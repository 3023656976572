
import { ROOT_ACTIONS } from '@/store/actions';
import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import { ProgramFormula, Phase, Program, ProgramType, StateType } from '@/types';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '@/firebase';
import { User } from '@/store/user/types';

@Component
export default class ExportData extends Vue {
  // @VModel({ required: true }) show!: boolean;
  // @Prop({ required: true, default: [] }) programList!: string[]

  // loading = false;

  // percentComplete = 0;
  // compilingExcel = false;

  // getFormula(ft: ProgramFormula) {
  //   switch (ft) {
  //     case ProgramFormula.AlgemeenOpzeg: {
  //       return 'Algemene opzeg'
  //     }
  //     case ProgramFormula.AlgemeenVerbreking: {
  //       return 'Algemene verbreking'
  //     }
  //     case ProgramFormula.Bijzonder: {
  //       return 'Bijzonder'
  //     }
  //     case ProgramFormula.Collectief: {
  //       return 'Collectief'
  //     }
  //     case ProgramFormula.MedischOvermacht: {
  //       return 'Medische overmacht'
  //     }
  //     case ProgramFormula.Vrijwillig: {
  //       return 'Vrijwillig'
  //     }
  //     default: {
  //       return 'Onbekende formule'
  //     }
  //   }
  // }

  // getType(type: ProgramType) {
  //   switch (type) {
  //     case ProgramType.Group: {
  //       return 'Groepsbegeleiding'
  //     }
  //     case ProgramType.Individual: {
  //       return 'Individuele begeleiding'
  //     }
  //     case ProgramType.Mixed: {
  //       return 'Gemengde begeleiding'
  //     }
  //     default: {
  //       return 'Onbekend type'
  //     }
  //   }
  // }

  // getStateType(ft: StateType) {
  //   switch (ft) {
  //     case StateType.Planned: {
  //       return 'Aangemaakt'
  //     }
  //     case StateType.Resumed: {
  //       return 'Hervat'
  //     }
  //     case StateType.Started: {
  //       return 'Gestart'
  //     }
  //     case StateType.Completed: {
  //       return 'Afgerond'
  //     }
  //     case StateType.Suspended: {
  //       return 'Stopgezet'
  //     }
  //   }
  //   return 'Onbekende status'
  // }

  // // async submit() {
  // //   try {
  // //     this.loading = true;
  // //     const wb = XLSX.utils.book_new();
  // //     wb.Props = {
  // //       Title: new Date().toISOString(),
  // //     }

  // //     wb.SheetNames.push('Export')

  // //     const programs = [] as Program[];

  // //     let i = 0;
  // //     this.percentComplete = 1;
  // //     for (let p of this.programList) {
  // //       const program = await getDoc(doc(firestore, 'programs', p));
  // //       const phases = await getDocs(query(collection(firestore, 'programs', p, 'phases')))

  // //       programs.push({
  // //         ...program.data(),
  // //         id: program.id,
  // //         phases: phases.docs.map(e => {
  // //           return {
  // //             ...e.data(),
  // //             id: e.id
  // //           } as Phase
  // //         })
  // //       } as Program)

  // //       this.percentComplete = (i / this.programList.length) * 100;
  // //       console.log(this.percentComplete)
  // //       i++;
  // //     }

  // //     this.percentComplete = 100;

  // //     this.compilingExcel = true;


  // //     const users = await getDocs(query(collection(firestore, 'users'), where('type', 'in', ['COACH', 'PARTICIPANT'])))


  // //     const coaches = users.docs.filter(e => e.data().type === 'COACH').map(e => {
  // //       return {
  // //         id: e.id,
  // //         ...e.data()
  // //       } as User;
  // //     });

  // //     const participants = users.docs.filter(e => e.data().type === 'PARTICIPANT').map(e => {
  // //       return {
  // //         id: e.id,
  // //         ...e.data()
  // //       } as User;
  // //     });

  // //     let data = [['Deelnemer', 'Coach', 'Type', 'Formule', 'Start datum', 'Taal', 'Huidige Fase', 'Status']];
  // //     for (let program of programs) {
  // //       let currentPhase = 'Nog niet gestart';
  // //       for (let phase of program.phases) {
  // //         if (new Date() >= new Date(phase.start) && new Date() <= new Date(phase.end)) {
  // //           currentPhase = phase.title;
  // //           break;
  // //         }
  // //       }

  // //       const subData = [] as string[];

  // //       const participant = participants.find(e => e.id === program.participant);
  // //       subData.push(participant ? `${participant.firstname} ${participant.lastname}` : 'Onbekende deelnemer')

  // //       const coach = coaches.find(e => e.id === program.coach);
  // //       subData.push(coach ? `${coach.firstname} ${coach.lastname}` : 'Onbekende Coach')

  // //       subData.push(this.getType(program.type))
  // //       subData.push(this.getFormula(program.formula));

  // //       subData.push(program.start)

  // //       subData.push(participant ? participant.language : 'Geen Taal');
  // //       subData.push(currentPhase)

  // //       subData.push(this.getStateType(program.state as StateType))

  // //       data.push(subData);
  // //     }

  // //     let ws = XLSX.utils.aoa_to_sheet(data);

  // //     wb.Sheets['Export'] = ws;

  // //     XLSX.writeFile(wb, `${new Date().toLocaleDateString()}.xlsx`)

  // //   } catch (error) {
  // //     this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
  // //       color: 'error',
  // //       text: error
  // //     })
  // //   } finally {
  // //     this.loading = false;
  // //     this.percentComplete = 0;
  // //     this.compilingExcel = false;
  // //   }
  // // }
} 
